<template>
    <v-content>
        <v-container fluid>
            <v-dialog max-width="500px" v-model="dialogDelete">
                <deleteListData :deleteFormData="deleteFormData" @formResponse="formResponse"></deleteListData>
            </v-dialog>
            <v-expansion-panels hover multiple>
                <v-expansion-panel>
                    <v-expansion-panel-header>Filter</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-container fluid>
                            <v-row col="12">
                                <v-row align="start" justify="end">

                                    <v-col class="d-flex" cols="12" sm="4">
                                        <v-btn :disabled="!columns" @click.stop="onDownloadData"
                                               class="success"
                                               cols="2">
                                            <v-icon>get_app</v-icon>
                                            Download Data
                                        </v-btn>
                                    </v-col>

                                    <v-col class="d-flex" cols="12" sm="3">
                                        <v-menu
                                                :nudge-right="40"
                                                :close-on-content-click="false"
                                                min-width="290px"
                                                offset-y
                                                transition="scale-transition"
                                                v-model="snackbar.menu1"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                        clearable
                                                        label="From"
                                                        prepend-icon="event"
                                                        readonly
                                                        v-model="serverParams.FromDate"
                                                        v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker v-model="serverParams.FromDate">

                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>

                                    <v-col class="d-flex" cols="12" sm="3">
                                        <v-menu
                                                :nudge-right="40"
                                                :close-on-content-click="false"
                                                min-width="290px"
                                                offset-y
                                                transition="scale-transition"
                                                v-model="snackbar.menu2"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                        clearable
                                                        label="To"
                                                        prepend-icon="event"
                                                        readonly
                                                        v-model="serverParams.ToDate"
                                                        v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker v-model="serverParams.ToDate">

                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>

                                    <v-col class="d-flex" cols="12" sm="2">
                                        <v-btn @click="loadItems" class="mx-2" color="primary" dark fab small>
                                            <v-icon dark>send</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-row>
                        </v-container>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <vue-good-table :columns="columns"
                            :rows="rows"
                            mode="remote"
                            styleClass="vgt-table condensed">
                <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'datedOn'">
                        <span>{{props.row.datedOn | moment("DD-MMM-YYYY")}}</span>
                    </span>
                    <!--<span v-else-if="props.column.field == 'actions'">
                        <v-icon small class="mr-2" @click.stop="editItem(props.row, 'HistoryForm')">edit</v-icon>
                        <v-icon small @click.stop="deleteItem(props.row)">delete</v-icon>
                    </span>-->
                </template>
            </vue-good-table>
            <v-dialog fullscreen
                      hide-overlay
                      persistent
                      transition="dialog-bottom-transition"
                      v-model="snackbar.formModal">
                <v-card>
                    <!-- <component :is="snackbar.dynamicComponent.current"
                        @dialogueClose="dialogueClose"
                        @formResponse="formResponse"
                        v-bind="historyData.updateItem"
             ></component>-->
                    <HistoryForm @dialogueClose="dialogueClose"
                                 @formResponse="formResponse"
                                 v-if="snackbar.createFormComponent"></HistoryForm>
                    <HistoryEditForm :editItemIndex="historyData.editItemIndex"
                                     @dialogueClose="dialogueClose"
                                     @formResponse="formResponse"
                                     v-if="snackbar.editFormComponent"></HistoryEditForm>
                </v-card>
            </v-dialog>
            <v-snackbar :timeout="snackbar.timeout"
                        color="success"
                        top
                        v-model="snackbar.snackbar">
                {{ callbackResponse.message }}
                <v-btn @click="snackbar.snackbar = false"
                       dark
                       text>
                    Close
                </v-btn>
            </v-snackbar>
        </v-container>
    </v-content>
</template>

<script>
    import axios from "axios";
    import HistoryForm from "./HistoryForm";
    import HistoryEditForm from "./HistoryEditForm";
    import deleteListData from "@/components/deleteModal";

    export default {
        name: "MountaineeringHistoryList",
        props: [
            'moduleName'
        ],
        components: {
            'HistoryForm': HistoryForm,
            'HistoryEditForm': HistoryEditForm,
            deleteListData
        },
        methods: {
            onDownloadData() {
                this.onDownloadDatas()
            },
            getNow: function () {
                const today = new Date();
                const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
                /* const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();*/
                this.serverParams.FromDate = date;
                this.serverParams.ToDate = date;
            },
            goBackToReportDashboard() {
                this.$emit('goBackToReportDashboard')
            },
            goBackToReportSelection() {
                this.$emit('goBackToReportSelection')
            },
            formResponse(data) {
                console.log({data})
                if (data.message) {
                    this.snackbar.formModal = false
                    this.snackbar.createFormComponent = false;
                    this.dialogDelete = false;
                    this.snackbar.editFormComponent = false;
                    this.callbackResponse = data;
                    this.snackbar.snackbar = true
                    this.loadItems();
                } else {
                    this.snackbar.createFormComponent = false;
                    this.dialogDelete = false;
                    this.snackbar.editFormComponent = false;
                    this.snackbar.formModal = false
                }
            },

            async onImport() {
                var form = new FormData();

                form.append(
                    "formFile",
                    this.formdata.import
                );
                let ImportResponse = await axios.post("Report/ImportTrekkingMonthlyReport", form);
                if (ImportResponse.data.success) {
                    this.loadItems();
                    this.formdata.import = null;
                } else {
                    return false;
                }
            },

            async onDownload() {
                await axios({
                    url: 'Report/DownloadMontlyReportSample',
                    method: 'POST',
                    responseType: 'blob'
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'SaarcTrekkingFile.xlsx'); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                });
            },
            async onDownloadDatas() {
                await axios({
                    url: 'Report/DownloadMontlyReport',
                    method: 'POST',
                    responseType: 'blob',
                    data: {
                        FromDate: this.serverParams.FromDate,
                        ToDate: this.serverParams.ToDate,
                    }
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'SaarcTrekkingData.xlsx'); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                });
            },

            onResponse(data) {
                if (data == true) {
                    this.dialogDelete = false
                    this.loadItems();
                } else {
                    this.dialogDelete = false
                }
            },

            dialogueClose() {
                this.historyData.editItemIndex = null
                this.snackbar.formModal = false
                this.snackbar.createFormComponent = false
                this.snackbar.editFormComponent = false
            },

            async loadItems() {
                this.snackbar.isLoading = true;
                let param = {
                    /* PageSize: this.serverParams.perPage,
                     PageNo: this.serverParams.page,*/
                    /*ReportType: this.serverParams.ReportType,*/
                    FromDate: this.serverParams.FromDate,
                    ToDate: this.serverParams.ToDate,
                    /* OrderType: this.serverParams.sort[0].type,
                     OrderBy: this.serverParams.sort[0].field,*/
                    /* FromDate: this.serverParams.FromDate,
                     ToDate: this.serverParams.ToDate,
                     ReportType: this.serverParams.ReportType,*/
                };
                axios.post('Report/GetTrekkingMonthlyReportListAsyc', param)
                    .then(response => {
                        this.rows = response.data.data;
                        this.totalRecords = response.data.totalCount;
                        this.snackbar.loading = false;
                    })
                    .catch(err => {
                        console.log(err)
                    });
            },

            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },

            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.loadItems();
            },

            onPerPageChange(params) {
                this.updateParams({perPage: params.currentPerPage==-1?this.totalRecords:params.currentPerPage});
                this.loadItems();
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadItems();
            },

            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },

            addModal() {
                this.snackbar.formModal = true
                this.snackbar.createFormComponent = true
            },

            editItem(item) {
                this.snackbar.formModal = true
                this.snackbar.editFormComponent = true
                this.historyData.editItemIndex = item.personInformationID
            },

            deleteItem(props) {
                this.dialogDelete = true;
                this.deleteFormData.url = "Mountain/DeleteHistoryInformationByIDAsync/" + props.personInformationID;
            },
        },
        mounted() {
            this.getNow()
            this.loadItems()
        },
        data() {
            return {
                tab: 'tab-monthlyReport',
                totalRecords: 0,
                callbackResponse: {
                    timeout: 6000
                },
                columns: [
                    {label: "months", field: "months"},
                    {label: "individual_Non_Saarc_National_Trekkers", field: "individual_Non_Saarc_National_Trekkers"},
                    {label: "individual_Saarc_National_Trekkers", field: "individual_Saarc_National_Trekkers"},
                    {label: "group_Non_Saarc_National_Trekkers", field: "group_Non_Saarc_National_Trekkers"},
                    {label: "group_Saarc_National_Trekkers", field: "group_Saarc_National_Trekkers"},
                    {label: "total_Non_Saarc_National_Trekkers", field: "total_Non_Saarc_National_Trekkers"},
                    {label: "total_Saarc_National_Trekkers", field: "total_Saarc_National_Trekkers"},
                    {label: "all_Total_Trekkers", field: "all_Total_Trekkers"},
                ],
                rows: [],
                ReportTypes: [
                    {
                        id: "yearwise", value: "yearwise"
                    },
                    {
                        id: "monthwise", value: "monthwise"
                    }
                ],
                dialogDelete: false,
                deleteFormData: [],
                formdata: [],
                historyData: {
                    totalRows: 0,
                    editItemIndex: null
                },
                serverParams: {
                    search: "",
                    columnFilters: {},
                    sort: [
                        {
                            field: "id",
                            type: "desc"
                        }
                    ],
                    page: 1,
                    perPage: 10,
                    FromDate: null,
                    ToDate: null,
                    ReportType: ""
                },
                snackbar: {
                    color: '',
                    snackbar: false,
                    text: '',
                    timeout: 6000,
                    isLoading: false,
                    formModal: false,
                    createFormComponent: false,
                    editFormComponent: false,
                    menu1: false,
                    menu2: false,
                }
            }
        }
    }
</script>
